import {inject, observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import cn from "classnames";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

const SocialMulti = inject('geoStore')(observer((props) => {
    const {geoStore, field, code, source} = props;

    const saveFieldList = (fieldValue) => {
        geoStore.setSetupFieldValue(code, source, field.fieldName, fieldValue);
    }

    const multiProps = {
        ...props,
        ...{saveFieldList, geoStore},
    }

    return(
        <Social {...multiProps}/>
    )


}))
const SocialSingle = inject('geoStore')(observer((props) => {
    const {geoStore, fieldName, onChange} = props;

    const saveFieldList = (fieldValue) => {
        onChange(fieldValue);
        geoStore.setFormFieldValue(fieldName, fieldValue);
    }

    const singleProps = {
        ...props,
        ...{saveFieldList, geoStore},
    }
    return(
        <Social {...singleProps}/>
    )
}));

const Social = (props) => {
    const {fieldName, saveFieldList} = props;

    const [socialUrlList, setSocialUrlList] = useState(['']);

    useEffect(()=>{
        saveFieldList(socialUrlList);
    }, [socialUrlList])

    const addItem = (key, value) => {
        setSocialUrlList(prev => {
            let copy = [...prev];
            copy[key] = value;
            return copy
        })
    }

    const onDeleteItem = (key) => {

        let copy = [
            ...[],
            ...socialUrlList
        ];

        if (key > -1) {
            copy.splice(key, 1);
        }
        setSocialUrlList(copy);
    }

    return (
        <>
            {socialUrlList.map((item, key)=>(
                <div className={cn("form__row")} key={key}>
                    <div className={cn('text-field')}>
                        <div className={cn("p-float-label")}>
                            <InputText
                                id={`${fieldName}_${key}`}
                                type="text"
                                name={`${fieldName}_${key}`}
                                value={socialUrlList[key]}
                                onChange={(e) => {
                                    addItem(key, e.target.value)
                                }}
                            />
                            <label htmlFor={`${fieldName}_${key}`}>Ссылка на социальные сети</label>
                        </div>
                    </div>
                    {key > 0 && <div className={cn('description-field')}>
                        <Button
                            label='Удалить'
                            className={cn('p-button-danger', 'p-button-outlined')}
                            onClick={() => {
                                onDeleteItem(key)
                            }}
                        />
                    </div>}
                </div>
            ))}

            <div className={cn("form__row")}>
                <div className={cn("btn-field")}>
                    <Button
                        disabled={!socialUrlList[0].length}
                        label='Добавить'
                        icon="pi pi-plus"
                        iconPos="left"
                        className={cn('p-button-outlined')}
                        onClick={() => {
                            const list = [
                                ...socialUrlList,
                                ...['']
                            ];
                            setSocialUrlList(list);
                        }}/>
                </div>
            </div>
        </>
    )
}

export {
    SocialMulti,
    SocialSingle,
}