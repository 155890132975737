import React, { useEffect, useState } from 'react';
import cn from "classnames";
import { AD_PLATFORM } from "../../../../../models/ad-platform";
import { sourcesForGeo } from "../../../../../helpers";
import { lossFunction } from "@amcharts/amcharts4/.internal/venn.js";

const DataSynchronizationInfo = () => {

    const [sources, setSources] = useState([])

    useEffect(() => {
        setSources(sourcesForGeo())
    }, [])

/*
    useEffect(()=>{
        console.log(findSourceByCode('yandex'))
    }, [sources])
*/

    const findSourceByCode = (code) => {
        return sources.find(source => source.value === code)
    }

    return (
        <>
            <div className={cn('block', 'synchronization_info')}>
                <div className={cn('block__title')}>
                    <h3>Синхронизация данных</h3>
                </div>
                {sources.length > 0 &&
                    <div className={cn('block__content')}>
                    <div className={cn('content')}>
                        <div className={cn('content__title', 'yandex_map')}>
                            <img src={findSourceByCode('yandex').ico} alt="" width={15} height={15} title={findSourceByCode('yandex').name}/>
                            {findSourceByCode('yandex').name}
                        </div>
                        <div className={cn('content__status', 'success')}>Опубликован на Яндекс Картах</div>

                        <div className={cn('content__info', 'info__title')}>Последняя проверка:</div>
                        <div className={cn('content__info')}>20:00, 03 июля</div>
                        <div className={cn('content__info', 'warning')}>
                            <span className={cn('pi', 'pi-times')}></span>
                            2 расхождения
                            <span className={cn('pi', 'pi-arrow-right')}></span>
                        </div>
                    </div>

                    <div className={cn('content')}>
                        <div className={cn('content__title', 'google_map')}>
                            <img src={findSourceByCode('google').ico} alt="" width={15} height={15} title={findSourceByCode('google').name}/>
                            {findSourceByCode('google').name}
                        </div>
                        <div className={cn('content__status', 'success')}>Опубликован на Яндекс Картах</div>

                        <div className={cn('content__info', 'info__title')}>Последняя проверка:</div>
                        <div className={cn('content__info')}>20:00, 03 июля</div>
                        <div className={cn('content__info', 'success')}><span className={cn('pi', 'pi-check')}></span>Расхождений нет</div>
                    </div>

                    <div className={cn('content')}>
                        <div className={cn('content__title', 'soon')}>
                            <img src={findSourceByCode('2gis').ico} alt="" width={15} height={15} title={findSourceByCode('2gis').name}/>
                            {findSourceByCode('2gis').name}
                        </div>
                        <div className={cn('content__status', 'soon')}>Данные скоро появятся</div>

                        <div className={cn('content__info', 'info__title')}>Последняя проверка:</div>
                        <div className={cn('content__info')}>20:00, 03 июля</div>
                        <div className={cn('content__info', 'warning')}>
                            <span className={cn('pi', 'pi-times')}></span>
                            10 расхождения
                            <span className={cn('pi', 'pi-arrow-right')}></span>
                        </div>

                    </div>
                    <div className={cn('content')}>
                        <div className={cn('content__title', 'soon')}>
                            ZOON
                        </div>
                        <div className={cn('content__status', 'soon')}>Данные скоро появятся</div>
                    </div>
                    <div className={cn('content')}>
                        <div className={cn('content__title', 'not_published')}>
                            OTZOVIK
                        </div>
                        <div className={cn('content__status', 'not_published')}>Не опубликован</div>

                    </div>
                </div>}
            </div>
        </>
    )
}

export default DataSynchronizationInfo;