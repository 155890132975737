import { InputMask } from "primereact/inputmask";

class OkInputMask extends InputMask {

    init() {
        if (this.props.mask) {
            this.tests = [];
            this.partialPosition = this.props.mask.length;
            this.len = this.props.mask.length;
            this.firstNonMaskPos = null;
            this.defs = {
                '9': '[0-9]',
                'n': '[1234569]',
                'a': '[A-Za-z]',
                '*': '[A-Za-z0-9]'
            };

            this.androidChrome = /chrome/i.test(navigator.userAgent) && /android/i.test(navigator.userAgent);

            let maskTokens = this.props.mask.split('');
            for (let i = 0; i < maskTokens.length; i++) {
                let c = maskTokens[i];
                if (c === '?') {
                    this.len--;
                    this.partialPosition = i;
                }
                else if (this.defs[c]) {
                    this.tests.push(new RegExp(this.defs[c]));
                    if (this.firstNonMaskPos === null) {
                        this.firstNonMaskPos = this.tests.length - 1;
                    }
                    if (i < this.partialPosition) {
                        this.lastRequiredNonMaskPos = this.tests.length - 1;
                    }
                }
                else {
                    this.tests.push(null);
                }
            }

            this.buffer = [];
            for (let i = 0; i < maskTokens.length; i++) {
                let c = maskTokens[i];
                if (c !== '?') {
                    if (this.defs[c])
                        this.buffer.push(this.getPlaceholder(i));
                    else
                        this.buffer.push(c);
                }
            }
            this.defaultBuffer = this.buffer.join('');
        }
    }
}

export default OkInputMask;
