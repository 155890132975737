import React, {useState} from "react";
import cn from "classnames";
import {TopFormFields} from "../../../../../stores/forms/topForm.store";

import Setup from "../Setup";
import {SocialSingle} from "./Social";
import {WebsiteSingle} from "./Website";

const SocialMedia = () => {

    const [websiteSingleValue, setWebsiteSingleValue] = useState('');
    const [socialSingleValue, setSocialSingleValue] = useState('');

    const WebsiteOnChange = (value) => {
        setWebsiteSingleValue(value[0])
    }

    const SocialOnChange = (value) => {
        setSocialSingleValue(value[0])
    }


    return (
        <>
            <div className={cn('block')}>
                <div className={cn('block__title')}>
                    <h3>Сайт и ссылки на социальные сети</h3>
                </div>
                <div className={cn('block__form')}>
                    <WebsiteSingle
                        fieldName={'websites'}
                        onChange={WebsiteOnChange}
                    />
{/*
                    <SocialSingle
                        fieldName={'fieldName18'}
                        onChange={SocialOnChange}
                    />
*/}
                    <div className={cn("form__row")}>
                        <div className={cn('title-field')}>
                            <h4>Настройка площадок</h4>
                        </div>
                    </div>
                    <div className={cn("form__row")}>
                        <Setup
                            disabled={!(websiteSingleValue?.length || socialSingleValue?.length)}
                            fields={
                                [
                                    new TopFormFields({
                                        fieldName: 'websites',
                                        code: 'websites',
                                        fieldLabel: 'Ссылка на сайт',
                                        fieldType: 'dynamic-text',
                                        fieldValue: '',
                                        required: false
                                    }),
/*
                                    new TopFormFields({
                                        fieldName: 'social',
                                        code: '',
                                        fieldLabel: 'Ссылка на социальные сети',
                                        fieldType: 'dynamic-text',
                                        fieldValue: '',
                                        required: false
                                    }),
*/
                                ]
                            }
                            code={"socialMediaSetup"}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export {
    SocialMedia,
}