import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import cn from "classnames";

const OkInputText = (props) => {

    const {
        value,
        required,
    } = props;

    const [blur, setBlur] = useState(false);
    const [invalid, setInvalid] = useState(false);

    useEffect(() => {
        if (required && blur === true) {
            setInvalid(!value);
        }
    }, [value, blur]);

    return (
        <>
            <InputText
                {...props}
                className={cn(props.className, {'p-invalid': invalid})}
                onBlur={() => {
                    setBlur(true);
                }}
            />
        </>
    )
}

export default OkInputText;
